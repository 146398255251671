import { render, staticRenderFns } from "./pending.vue?vue&type=template&id=23603eae"
import script from "./pending.vue?vue&type=script&lang=js"
export * from "./pending.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Login: require('/root/containers/hermes_xenoglosso/components/core/login.vue').default})
